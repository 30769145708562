.modal-backdrop {
    background-color: #ebebebbb;
    position: fixed;
    border-radius: 15px;
    width: 70%;
    overflow-y: hidden;
    padding: 0.3rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: scale(0.9);
    animation: expand 0.3s forwards;
}

.modal-image {
    width: 50%;
    max-width: 100%;
    max-height: 100%;
}

.modal-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.modal-right {
    width: 50%;
    max-width: 100%;
    max-height: 100%;
}

.modal-content-right {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
}

.modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .modal-right-traits {
    display: grid;
    grid-template-columns: repeat(2, minmax(180px, 1fr));
    grid-gap: 1rem;
    padding-top: 2rem;
  }

  .modal-right-traits li {
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.375rem;
    padding: 1rem;
  }

  .modal-right-traits .trait-type {
    font-family: monospace;
    font-size: 0.75rem;
    text-transform: uppercase;
    opacity: 0.6;
    margin-right: 0.25rem;
  }

  .modal-right-traits .trait-value {
    font-weight: 600;
    font-size: 0.875rem;
    text-transform: uppercase;
  }

  .attribute-item {
    display: flex;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 0.5rem;
    width: auto;
    align-items: center;
}

.modal-svg-icon {
    width: 20px; /* adjust the width and height to match your SVG icon */
    height: 20px;
    margin-right: 10px; /* add some spacing between the icon and text */
  }

  .modal-trait-info {
    display: flex;
    flex-direction: column;
  }


.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(131, 131, 131, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: fadeIn 0.3s forwards;
    z-index: 1000;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

.modal-content {
    background-color: #6d6c6a;
    display: flex;
    border-radius: 11px;
    width: 100%;
    max-height: calc(100% - 10%);
    overflow-y: hidden;
    opacity: 0;
    transform: scale(0.9);
    animation: expand 0.3s forwards;
}

@keyframes expand {
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.modal-header-flex {
    display: flex;
    justify-content: space-between;
    flex: 1
}

.magic-eden-logo {
    height: 24px;
    width: 24px;
    border-radius: 5px;
    margin-left: 0.5rem;
}

.modal-header-left {
    justify-content: flex-start;
  }
  
  .modal-header-right {
    justify-content: flex-end;
  }

  .modal-header-text {
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    opacity: 0.6;
}

.modal-id {
    color: #292929;
    font-size: 3rem;
}

.modal-content {
  background-color: #6d6c6a;
}
.modal-content[data-background="Cyan"] {
  background-color: #8ac4c3;
}
.modal-content[data-background="Cream"] {
  background-color: #ffe0b7;
}
.modal-content[data-background="Blue"] {
  background-color: #42bfe8;
}
.modal-content[data-background="Light Cyan"] {
  background-color: #dceaee;
}
.modal-content[data-background="Purple"] {
  background-color: #ceaaed;
}
.modal-content[data-background="Pink"] {
  background-color: #f6a2a8;
}
.modal-content[data-background="Steel Blue"] {
  background-color: #88a3bc;
}
.modal-content[data-background="Yellow"] {
  background-color: #f8c53a;
}
.modal-content[data-background="Green"] {
  background-color: #3ef497;
}
.modal-content[data-background="Violet"] {
  background-color: #d964ff;
}
.modal-content[data-special="Thunder"] {
  background-color: #ab8746;
}
.modal-content[data-special="Devil"] {
  background-color: #bd3c3c;
}
.modal-content[data-special="Angel"] {
  background-color: #46c3e8;
}
.modal-content[data-special="Earth"] {
  background-color: #b47538;
}
.modal-content[data-special="Fire"] {
  background-color: #e48533;
}
.modal-content[data-special="Astral"] {
  background-color: #a794e0;
}
.modal-content[data-special="Robotic"] {
  background-color: #bda499;
}
.modal-content[data-special="Ice"] {
  background-color: #4ad1e0;
}
.modal-content[data-special="Skull"] {
  background-color: #c0c0bf;
}
.modal-content[data-special="8bits"] {
  background-color: #c6b858;
}

@media (max-width: 1024px) {

  .modal-id {
    color: #292929;
    font-size: 2rem;
}

.modal-header-text {
  font-weight: bold;
  color: #333;
  text-transform: uppercase;
  opacity: 0.6;
  font-size: 0.5rem;
}

}

.download-button {
  max-width: 250px;
  display: block; /* Ensure the button is a block element */
  margin: 0 auto; /* Center the button horizontally */
  background-color: #292929;
  white-space: nowrap;
}

.download-button:hover {
  background-color: #6e6e6e;
  transform: scale(1.01);
}

.hide-for-screenshot {
  display: none !important;
}

.download-flex {
  display: flex;
}

@media (max-width: 520px) {
  .download-button {
    max-width: 250px;
    display: block; /* Ensure the button is a block element */
    margin: 0 auto; /* Center the button horizontally */
    background-color: #292929;
    white-space: nowrap;
  }

  .button-icon {
display: flex !important;
   }
}

.button-icon {
 height: 15px;
 width: 15px;
}

.buttongap {
  margin-right: 4px;
}

.pixelated {
  image-rendering: pixelated;
}
