.holders-background {
    min-height: 100vh;
    background-image: url(/public/faqandbee.gif);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.holder-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* Align vertically */
    padding: 10px 0;
    border-bottom: 1px solid black;
}

.holder-row:first-child {
    border-bottom: 2px solid black;
    /* Add this line */
}



.holder-box {
    background-color: rgba(222, 230, 234, 0.902);
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 60vh;
    margin-top: 1rem;

}

.collection-stats {
    background-color: rgba(222, 230, 234, 0.902);
    padding: 20px;
    border-radius: 10px;
    overflow-y: auto;
    max-height: 60vh;
    margin-top: 10rem;
    display: flex;
    justify-content: space-between;
    text-align: center;
}


.pagination-controls {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination-controls button {
    margin: 0 2px;
    padding: 5px 20px !important;
    background-color: rgb(188, 188, 188);
    border: 1px solid rgb(156, 156, 156);
    font-size: 16px;
    color: black;
}

.pagination-controls button:hover {
    background-color: rgb(149, 149, 149);
}

.pagination-controls button.arrow {
    transform: scale(0.8);
}

.holder-box::-webkit-scrollbar {
    display: none;
}

.holder-number {
    width: 25px;
    text-align: left;
    margin-left: 60px;
}

.holder-address {
    flex-grow: 1;
    margin-left: 50px;
    margin-right: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: #086c9f;
    text-decoration: none;
    font-family: Arial, sans-serif;
}

.address-title {
    flex-grow: 1;
    margin-left: 50px;
    margin-right: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: inherit;
    /* Ensures the color stays consistent with the rest of the text */
    text-decoration: none;
    /* Removes underline */
}


@media (min-width: 1367px) {
.holder-address,
.address-title {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 500px); /* Adjust the calculation according to your needs */
    margin-left: 50px;
    margin-right: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-decoration: none;
}
}


.holder-holds {
    width: 150px;
    text-align: left;
}

.holder-percent {
    width: 150px;
    text-align: left;
}

.supply-title {
    font-size: 20px;
}

.holder-title {
    font-size: 20px;
}

.link-title {
    font-size: 20px;
}

@media (max-width: 340px) {

    .holder-box {
        max-width: 250px;
    }

    .holder-address,
    .address-title {
        margin-left: 2%;
        margin-right: 2%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 150px) !important;
        max-width: 50px !important; 
    }

    .holder-number,
    .holder-holds,
    .holder-percent {
        margin-left: 0;
        text-align: center;
        max-width: 50px !important;
    }

}

@media (max-width: 480px) {
    .holder-row {
        flex-direction: row;
        padding: 5px 0;
        font-size: 8px !important;
    }
    .holder-box,
    .collection-stats {
        max-height: 60vh;
        padding: 10px;
        margin-top: 1rem;
    }

    .collection-stats {
        max-height: 100vh;
        padding: 10px;
        margin-top: 1rem;
        font-size: 10px !important;
    }

    .collection-stats h3 {
        font-size: 10px !important;
    }

    .holder-number,
    .holder-holds,
    .holder-percent {
        margin-left: 0;
        text-align: center;
        max-width: 75px;
    }

    .holder-address,
    .address-title {
        margin-left: 2%;
        margin-right: 2%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 250px);
        max-width: 100px; 
    }

    .holder-address {
        font-size: 10px; /* Adjust according to your need */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
}

@media (min-width: 481px) and (max-width: 768px) {
    .holder-row {
        flex-direction: row;
        padding: 10px 0;
    }
    .holder-box,
    .collection-stats {
        max-height: 80vh;
        padding: 15px;
        margin-top: 2rem;
    }

    .collection-stats {
        max-height: 100vh;
        padding: 10px;
        margin-top: 8rem;
        font-size: 10px;
    }

    .collection-stats h3 {
        font-size: 10px !important;
    }

    .holder-number,
    .holder-holds,
    .holder-percent {
        margin-left: 20px;
        text-align: left;
        max-width: 100px;
    }
    .holder-address,
    .address-title {
        margin-left: 2%;
        margin-right: 2%;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(100% - 250px);
        max-width: 175px; 
    }

    .holder-address {
        font-size: 12px; /* Adjust according to your need */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
}

@media (min-width: 769px) and (max-width: 1024px) {
    .holder-box,
    .collection-stats {
        max-height: 70vh;
        padding: 20px;
    }
    
    .holder-number,
    .holder-holds,
    .holder-percent {
        width: 100px;
        margin-left: 30px;
        text-align: left;
    }
    .holder-address,
    .address-title {
        margin-left: 2%;
        margin-right: 2%;
    }

    .holder-address {
        font-size: 10px; /* Adjust according to your need */
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    
}

@media (min-width: 1025px) and (max-width: 1366px) {
    .holder-box,
    .collection-stats {
        max-height: 65vh;
        padding: 25px;
    }
    .holder-number,
    .holder-holds,
    .holder-percent {
        width: 125px;
        margin-left: 40px;
        text-align: left;
    }
    .holder-address,
    .address-title {
        margin-left: 2%;
        margin-right: 2%;
    }
    
}

@media (min-width: 1367px) and (max-width: 1920px) {
    .holder-box,
    .collection-stats {
        max-height: 60vh;
        padding: 20px;
    }
    .holder-number,
    .holder-holds,
    .holder-percent {
        width: 150px;
        margin-left: 50px;
        text-align: left;
    }
    .holder-address,
    .address-title {
        margin-left: 2%;
        margin-right: 2%;
    }
    
}

@media (min-width: 1921px) {
    .holder-box,
    .collection-stats {
        max-height: 60vh;
        padding: 20px;
    }
    .holder-number,
    .holder-holds,
    .holder-percent {
        width: 150px;
        margin-left: 60px;
        text-align: left;
    }
    .holder-address,
    .address-title {
        margin-left: 2%;
        margin-right: 2%;
    }
    
}
