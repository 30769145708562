.artg-background {
  min-height: 100vh;
  background-image: url(/public/faqandbee.gif);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}


.art-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.preview-canvas {
  max-width: 50%;
  max-height: 600px;
  margin-right: 20px;
  min-height: 400px;
  image-rendering: pixelated;
}

.options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.options-container h2 {
  color: #007bff;
}

.stock-images-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 15px;
}

.stock-image {
  width: 80px;
  height: auto;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.2s ease-in-out;
  border: 1px solid #ccc;
  border-radius: 5px;
  object-fit: cover;
}

.stock-image.selected {
  border-color: #007bff;
}

.remove-background-container {
  margin-bottom: 20px;
}

.art-download-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.art-download-button:hover {
  background-color: #004fa3;
}

.reupload-button:hover {
  background-color: transparent;
}

.reupload-container {
  display: flex;
  justify-content: center;
}

.reupload-button {
  background-color: transparent;
  color: #007bff;
  border: none;
  cursor: pointer;
  margin-bottom: 3px;
}

h2 {
  margin-bottom: 10px;
}

.upload-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-label {
  font-size: 18px;
  font-weight: bold;
  color: #007bff;
  cursor: pointer;
}

.upload-input {
  display: none;
}

.upload-icon {
  font-size: 20px;
  margin-right: 8px;
}

.flex-combine {
  display: flex;
  margin-top: 15px;
}

.color-picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 25px;
}

.color-picker {
  margin-bottom: 10px;
}

.color-switch {
  border-radius: 5px;
  max-height: 25px;
  margin-left: 5px;
}

.flex-1 {
  display: flex;
}

.art-transparent-button {
  padding: 10px 20px;
  background-color: #007bff00;
  color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.art-transparent-button:hover {
  background-color: transparent;
}

.upload-gif img {
  border-radius: 15px;
  max-width: 600px;
margin-bottom: 1rem;
}

