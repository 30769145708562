.den-background {
    min-height: 100vh;
    background-image: linear-gradient(to top, rgba(100, 50, 25, 1) 0%, rgba(100, 50, 25, 0) 15%), url(/public/faqandbee.gif), linear-gradient(to bottom, rgba(100, 50, 25, 0.8) 0%, rgba(100, 50, 25, 0) 20%);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

}

.second-page {
    position: relative;
    display: flex;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to bottom, rgba(100, 50, 25, 1) 5%, rgb(90, 33, 7) 30%, rgba(78, 29, 6, 1) 50%), #643219e0;
}

.third-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: rgba(78, 29, 6, 1);
}


.bear-image {
    position: absolute;
    bottom: 0;
    max-width: 50%;
    z-index: 1;
    /* Add a z-index to the bear image */
}

.bear-image.sticky {
    position: fixed;
    bottom: 0;
    max-width: 50%;
    z-index: 1;
    /* Add a z-index to the bear image */
}

@media (max-width: 1024px) {
    .bear-image {
        display: none;
    }
}

@media (max-width: 416px) {
    .third-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 140vh;
        background-color: rgba(78, 29, 6, 1);
    }
}