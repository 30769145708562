@font-face {
    font-family: 'BearFont';
    src: url('/public/bear-font.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BearFont2';
    src: url('/public/bear-font-2.otf') format('truetype');
  }

.team-section {
    padding: 2rem;
    padding-right: 6rem;
    background-color: rgba(78, 29, 6, 1);
    color: #fff;
    width: 45%;
    margin-left: auto;
    font-family: 'BearFont2', sans-serif;
  }
  
  .team-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(225px, 1fr));
    gap: 1rem;
  }
  
  .team-member {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #fff;
    padding: 0.5rem;
    height: 75px;
  }
  
  .team-member div h3,
  .team-member div p {
    margin: 0;
    white-space: nowrap;
    font-family: 'BearFont', sans-serif;
  }

  .team-member div {
    width: 100%;
    text-align: center;
    overflow: hidden;
  }

  .team-description {
    font-size: 18px;
    line-height: 1.4;
  }
  
  .team-title {
    font-size: 60px;
  }

  .member-position {
    font-family: 'BearFont2', sans-serif !important;
  }
  
  .team-member div h3 {
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: 'BearFont2', sans-serif;
  }
  
  .team-member div p:not(:last-child) {
    margin-bottom: 5px;
  }
  
  .team-member-image {
    width: 50px; 
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .svg-link {
    padding-left: 10px;
  }

  @media (max-width: 1024px) {
    .team-section {
      padding: 1rem;
      width: auto;
      max-width: 80%;
      margin-left: 0;
    }
  
    .team-grid {
      grid-template-columns: repeat(auto-fill, minmax(100px, 2fr));
    }
  
    .team-member {
      height: auto;
      flex-direction: column;
    }
  
    .team-member-image {
      width: 40px;
      height: 40px;
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .team-title {
      font-size: 30px;
    }
  
    .team-description {
      font-size: 14px;
    }
  
    .team-member div h3,
    .team-member div p {
      font-size: 14px;
    }
  
    .member-position {
      font-size: 12px;
    }
  
    .svg-link {
      padding-top: 10px;
      padding-left: 0;
    }
  }

  @media (max-width: 416px) {
    .team-section {
      padding: 1rem;
      width: 100%;
      margin-left: 0;
      padding-top: 4rem;
    }

    .team-member {
        height: auto;
        flex-direction: column;
      }

    .team-grid {
        grid-template-columns: repeat(auto-fill, minmax(75px, 2fr));
      }

      

}

@media (min-width: 1024px) and (max-width: 1400px) {
    .team-section {
        padding-right: 3rem;
        width: 50%;
      }
    
      .team-description {
        font-size: 16px;
      }
    
      .team-title {
        font-size: 50px;
      }
    
      .team-member {
        height: 60px;
      }
    
      .team-member-image {
        width: 40px; 
        height: 40px;
      }
    
      .member-position, 
      .team-member div h3, 
      .team-member div p {
        font-size: 14px;
      }
  }

  @media (min-width: 1400px) and (max-width: 1650px) {
    .team-section {
        padding-right: 4rem;
        width: 50%;
      }
    
      .team-description {
        font-size: 17px;
      }
    
      .team-title {
        font-size: 55px;
      }
    
      .team-member {
        height: 70px;
      }
    
      .team-member-image {
        width: 45px; 
        height: 45px;
      }
    
      .member-position, 
      .team-member div h3, 
      .team-member div p {
        font-size: 16px;
      }
  }
  

  
  