@media only screen and (max-width: 1024px) {

    .filter-container {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        background-color: #43445e;
        z-index: 1;
        white-space: nowrap;
        height: calc(100vh - 3.5rem) !important;
        overflow-y: auto !important;
    }

    .filter-parent {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        z-index: 999;
        background-color: #43445e;
    }

    .filter-empty {
        display: none;
    }

    .filter-header {
        display: none;
    }

    .hidden {
        display: none;
    }

    .grid-header-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 20;
        height: 3.5rem;
        padding: 6.5rem 1.2rem 2rem;
        background-color: #43445e;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    

 

    .grid-header {
        display: flex;
        align-items: center;
    }

    .filter-button {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    .filter-button svg {
        filter: invert(1);
    }

    .mobile-filter-header {
        display: flex !important;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        box-sizing: border-box;
    }

    .mobile-filter-title {
        margin: 0 !important;
        margin-left: 2rem !important;
        flex: 1;
        text-align: center;
        color: white;
    }

    .mobile-filter-close {
        cursor: pointer !important;
        font-size: 1.2rem !important;
        border: none !important;
        background-color: transparent !important;
        color: white !important;
        display: inline-block !important;
    }


    .filter-section-top h3,
    .filter-section h3 {
        padding-left: 1rem;
    }

    .plus-button {
        margin-left: auto;
        margin-right: 1rem;
      }

    .filter-section h3::after,
    .filter-section-top h3::after {
        padding-right: 1rem;
    }

    .filter-section h3.expanded::after,
    .filter-section-top h3.expanded::after {
        padding-right: 1rem;
    }



    .search-bar-container {
        padding-left: 1.5rem;
    }


      .expand-search-bar {
        margin-left: 1rem;
    }

    .checkbox-container {
        margin-left: 1rem;
    }

    .ordinal-count {
        font-size: 22px;
        margin-right: -5px;
        margin-bottom: 3px;
    }

    .search-icon-svg {
        margin-top: 10px;
        margin-right: 7px;
        margin-left: -15px;
    }

    .gridhead-container {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background-color: #E5E5E5;
        padding: 4px;
        margin-top: 18px;
        margin-bottom: 16px;
        border-radius: 15px;
        height: 40px;
        pointer-events: none;
        margin-left: 0;
    }

    .grid-header {
        display: flex;
        padding-bottom: 0rem;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }

}

.mobile-filter-header {
    display: none;
}