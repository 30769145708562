@media only screen and (max-width: 1024px) {

  .button-container {
    display: none !important;
  }

  .button-container-page {
    display: none !important;
  }

  .hamburger {
    display: block !important;
    font-size: 2rem;
  }

  .menu-container {
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(73, 113, 146, 0.7);
    z-index: 100;
    overflow-y: hidden;
    --tw-backdrop-filter: blur(16px);
    -webkit-backdrop-filter: var(--tw-backdrop-filter);
    backdrop-filter: var(--tw-backdrop-filter);
  }

  .menu-items {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    list-style: none;
    padding: 0;
    text-transform: uppercase;
  }

  .menu-items li {
    margin-bottom: 1.5rem;
    margin-top: -1rem;
    width: 90%;
    border-bottom: 1px solid rgba(14, 14, 14, 0.212);
    padding-bottom: 0.8rem;
  }

  .menu-items li:last-child {
    margin-bottom: 0;
  }

  .menu-items button,
  .menu-items a {
    display: block;
    width: 100%;
    padding: 0rem;
    border: none;
    border-radius: 5px;
    color: #d9e1e6;
    font-size: 1.5rem;
    text-align: left;

    text-decoration: none;
    transition: background-color 0.2s ease-in-out;
  }

  .menu-items button:hover,
  .menu-items a:hover {
    background-color: #333;
    color: #fff;
    cursor: pointer;
  }

  .menu-items button:focus,
  .menu-items a:focus {
    outline: none;
  }

  .close-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transform: scale(1.5);
  }

  .svg-item {
    display: flex;
    align-items: center;
  }

  .svg-item svg {
    filter: invert(1);
  }

}
