.layout {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }
  
  .main-content {
    flex-grow: 1;
    margin-top: 5rem;
  }
  
  .main-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 500;
    padding-left: 1rem;
    padding-right: 1rem;
    box-sizing: border-box;
    height: 5rem;
  }

  .fire-area.playing,
  .fire-area.paused {
    caret-color: transparent;
    display: none;
  }
  
  @media (min-width: 1900px) and (max-width: 1940px) {
    .fire-area.playing,
    .fire-area.paused {
      caret-color: transparent;
      display: block;
    }
  }

  @media (min-width: 2550px) and (max-width: 2570px) {
    .fire-area.playing,
    .fire-area.paused {
      caret-color: transparent;
      display: block;
    }

    .fire-area {
      position: absolute;
    bottom: 170px !important;
    left: 900px !important;
    width: 10%;
    height: 20%;
    }
  }

  @media (min-width: 3830px) and (max-width: 3850px) {
    .fire-area.playing,
    .fire-area.paused {
      caret-color: transparent;
      display: block;
    }

    .fire-area {
      position: absolute;
    bottom: 250px !important;
    left: 1350px !important;
    width: 10%;
    height: 20%;
    }
  }

  @media (min-width: 1590px) and (max-width: 1610px) {
    .fire-area.playing,
    .fire-area.paused {
      caret-color: transparent;
      display: block;
    }

    .fire-area {
      position: absolute;
    bottom: 110px !important;
    left: 570px !important;
    width: 10%;
    height: 20%;
    }
  }
  
  

  @media (min-width: 1024px) {

    .main-header {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        box-sizing: border-box;
      }
      
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.gallery-background {
  background-image: url(/public/faqandbee.gif);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-background {
  background-image: url(/public/bbchome.gif);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1536px) {
  .home-background {
    background-image: url(/public/bbcfinal.gif);
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media (max-width: 1024px) {
  .home-background {
    position: relative !important;
  }
  
  .home-background::before {
    content: "";
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url(/public/bbchomefinal.gif) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    z-index: 20;
  }

  
}


.audio-control {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #8a6857;
  background-color: #e5b680;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.audio-control svg {
  transform: scale(1.5);
}




