.BEARCUBS-section {
    padding: 2rem;
    padding-top: 10rem;
    padding-right: 6rem;
    color: #fff;
    width: 45%;
    margin-left: auto;
    font-family: 'BearFont2', sans-serif;
  }

  .BEARCUBS-description {
    font-size: 25px;
    line-height: 1.4;
  }

  .BEARCUBS-title {
    font-size: 80px;
  }

  .bearcubs-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .bearcubs-item button {
    font-size: 40px;
    margin-top: 1rem;
    padding-top: 14px;
  }

  @media (max-width: 1024px) {
    .BEARCUBS-section {
      padding: 1rem;
      width: 100%;
      margin-left: 0;
      padding-top: 2rem;
    }
  
    .BEARCUBS-description {
      font-size: 18px;
      text-align: justify;
    }
  
    .BEARCUBS-title {
      font-size: 40px;
      text-align: center;
    }
  
    .bearcubs-item {
      flex-direction: column;
    }
  
    .bearcubs-item button {
        font-size: 20px;
        padding-top: 10px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1400px) {
    .BEARCUBS-section {
        padding-top: 6rem;
      }
    
      .BEARCUBS-description {
        font-size: 20px;
      }
    
      .BEARCUBS-title {
        font-size: 60px;
      }
    
      .bearcubs-item button {
        font-size: 30px;
        padding-top: 10px;
      }
  }

  @media (min-width: 1400px) and (max-width: 1650px) {
    .BEARCUBS-section {
        padding-top: 8rem;
      }
    
      .BEARCUBS-description {
        font-size: 22px;
      }
    
      .BEARCUBS-title {
        font-size: 70px;
      }
    
      .bearcubs-item button {
        font-size: 35px;
        padding-top: 12px;
      }
  }
  
  