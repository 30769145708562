@media only screen and (max-width: 1024px) {

    .modal-content {
        flex-direction: column !important;
    }

    .modal-backdrop {
        width: 50%;
        max-height: 80%;
    }
    
    .modal-image {
        width: 100%;
        height: 50%;
        max-width: 100%;
        max-height: 100%;
    }
    
    .modal-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .modal-right {
        width: auto;
        max-width: 100%;
        max-height: 100%;
    }

    .modal-right-traits {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        white-space: nowrap;
      }

      .modal-right-traits::-webkit-scrollbar {
        width: 8px;
        height: 10px;
      }
      
      .modal-right-traits::-webkit-scrollbar-track {
        background-color: #f5f5f500;
      }
      
      .modal-right-traits::-webkit-scrollbar-thumb {
        background-color: #4e4e4e;
        border-radius: 20px;
      }

      .refresh-button {
        padding: 8px !important; /* Adjust the padding as needed */
        position: relative;
        background-color: transparent;
    }
    
    .refresh-button::before {
        content: "";
        position: absolute;
        top: 4px !important;
        left: 0;
        right: 0;
        bottom: 8px !important;
        background-color: #0000001A;
        z-index: -1;
        border-radius: 4px;
    }
    
    .refresh-button:hover {
        background-color: transparent;
    }
    
    .refresh-button:hover .refresh-svg {
        opacity: 0.5;
        transition: opacity 0.3s ease;
    }
    
    .refresh-svg {
        width: 20px !important;
        height: 12px !important;
        padding-top: 0px !important;
        display: block !important;
        margin: 0 auto !important;
    }

    .ordinal-count-refresh {
        display: flex;
        align-items: center;
        margin-bottom: -3px;
      }
  
}

@media only screen and (max-width: 728px) {

    .modal-content {
        flex-direction: column !important;
    }

    .modal-backdrop {
        width: 70%;
        max-height: 80%;
    }

    .modal-image {
        width: 100%;
        height: 50%;
        max-width: 100%;
        max-height: 100%;
    }
    
    .modal-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .modal-right {
        width: auto;
        max-width: 100%;
        max-height: 250px;
    }

    .modal-right-traits {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        white-space: nowrap;
      }
}

@media (min-width: 1024px) and (max-width: 1200px) {
        .modal-content {
        flex-direction: column !important;
    }

    .modal-backdrop {
        width: 40%;
        max-height: 100%;
    }

    .modal-image {
        width: 100%;
        height: 50%;
        max-width: 100%;
        max-height: 100%;
    }
    
    .modal-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .modal-right {
        width: auto;
        max-width: 100%;
        max-height: 100%;
    }

    .modal-right-traits {
        display: flex;
        flex-wrap: nowrap;
        overflow-x: scroll;
        white-space: nowrap;
      }

      .modal-right-traits::-webkit-scrollbar {
        width: 8px;
        height: 10px;
      }
      
      .modal-right-traits::-webkit-scrollbar-track {
        background-color: #f5f5f500;
      }
      
      .modal-right-traits::-webkit-scrollbar-thumb {
        background-color: #4e4e4e;
        border-radius: 20px;
      }

}

@media (min-width: 880px) and (max-width: 1024px) {
    .modal-backdrop {
        width: 50%;
        max-height: 100%;
    }
}

@media (min-width: 630px) and (max-width: 730px) {
    .modal-backdrop {
        width: 60%;
        max-height: 100%;
    }
}