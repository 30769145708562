@font-face {
    font-family: 'BearFont';
    src: url('/public/bear-font.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BearFont2';
    src: url('/public/bear-font-2.otf') format('truetype');
  }

.plus-button .minus-svg {
    display: none;
}

.plus-button.plus .plus-svg {
    display: block;
    filter: invert(1);
}

.plus-button.minus .plus-svg {
    display: none;
}

.plus-button.minus .minus-svg {
    display: block;
    filter: invert(1);
}


.plus-button svg {
    height: 15px;
    width: 15px;
}

.plus-button {
    margin-left: auto;
    margin-right: 0.3rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.icon-svg svg {
    height: 20px;
    width: 20px;
    padding-right: 0.5rem;
    filter: invert(1);
}

.filter-section h3 {
    text-align: left;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-bottom: 10px;
    margin-top: -15px;
    margin-bottom: 30px;
    caret-color: #dfc3a9;
    color: white;
    display: flex;
}

.filter-options label {
    display: block;
    text-align: left;
    font-size: 0.7rem;
    cursor: pointer;
}

.filter-options {
    margin-top: -40px;
}


.filter-section .filter-options label:last-child
 {
    border-bottom: none;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.filter-section h3.expanded {
    border-bottom: none;
}

.filter-button {
    display: block;
}

.filter-parent {
    height: 100vh;
    flex-grow: 1;
    width: 25%;
    position: sticky;
    padding-top: 0rem;
    top: 0;
    grid-column-start: 1;
    z-index: 2;
}

.filter-padding {
    height: 8.7rem;
}

.filter-container {
    max-height: calc(100vh - 15rem);
    height: auto;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    text-transform: uppercase;
    background-color: #383953a8;
    border-radius: 12px;
    font-family: 'BearFont2', sans-serif !important;
    letter-spacing: 3px;
    font-size: 24px;
}

@media (min-width: 1024px) {
    .filter-container {
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 100px;
        margin-top: 20px;
    }
}

.filter-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.hamburger {
    display: none;
}

.filter-header {
    display: block;
    padding-bottom: 0rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    text-transform: uppercase;
    color: white;
}

.filter-results {
    padding-top: 0rem;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: white;
    font-family: 'BearFont2', sans-serif !important;
    letter-spacing: 3px;
    font-size: 16px;
}

@media (min-width: 1024px) {
    .filter-results {
        padding-top: 1.25rem !important;
    }

    .filter-button {
        display: none !important;
    }
}

.applied-filter {
    margin: 0.25rem;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    padding: 0.375rem 0.5rem;
    font-size: 0.75rem;
    font-weight: 400;
    text-transform: uppercase;
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 9999px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
}

.applied-filter:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.applied-filter-text {
    display: inline-block;
}


.remove-filter {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: #dbdbdb;
    margin-left: 0.25rem;
    height: 1rem;
    width: 1rem;
    padding: 0.25rem;
    font-size: 13px;
    border-radius: 9999px;
    opacity: 0.4;
    transition: opacity 0.2s ease-in-out;
}

.ordinal-count {
    border-radius: 4px;
    padding: 4px 8px;
    margin-left: 8px;
    font-size: 40px;
    margin-top: 5px;
    color: rgba(255, 255, 255, 0.9);
}

.filter-count {
    background-color: rgb(224, 224, 224);
    border-radius: 4px;
    padding: 3px 6px;
    margin-left: 8px;
    margin-right: 1rem;
    color: rgba(0, 0, 0, 0.6);
}

.filter-count-text {
    font-size: 14px;
    margin-left: 2px;
    margin-top: 1px;
}

/* Custom circle slider CSS */

.checkbox-container {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 14px;
    user-select: none;
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 40px;
    background-color: #ccc;
    border-radius: 34px;
}

.checkmark:before {
    position: absolute;
    content: "";
    height: 18px;
    width: 18px;
    left: 1px;
    bottom: 1px;
    background-color: white;
    border-radius: 50%;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.checkbox-container input:checked+.checkmark {
    background-color: #2196F3;
}

.checkbox-container input:checked+.checkmark:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
}

.checkbox-container input:focus+.checkmark {
    box-shadow: 0 0 1px #2196F3;
}

.label-text {
    margin-left: 10px;
    font-size: 13px;
    letter-spacing: 1px;
    color: white;
}


.search-bar-container {
    display: flex;
    margin-bottom: 15px;
    margin-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.buttons-flex {
    margin-bottom: 30px;
    padding-bottom: 15px;
    justify-content: space-between;
    display: flex;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.search-bar-container input {
    width: 88%;
    padding: 8px;
    border: 1px solid #9393aa;
    border-radius: 4px;
    font-size: 14px;
    background-color: #6b6b85;
    color: white;
}

.search-bar-container input::placeholder {
    color: rgb(255, 255, 255); /* Replace with your desired color */
}

.search-icon-svg {
    margin-top: 4px;
    margin-right: 4px;
    margin-left: -2px;
}

.search-icon-svg svg {
    filter: invert(1);
}

.expand-search-bar {
    margin-bottom: 10px;
    margin-top: 15px;
}

.expand-search-bar input {
    width: 90%;
    padding: 8px;
    border: 1px solid #9393aa;
    border-radius: 4px;
    font-size: 14px;
    background-color: #6b6b85;
}

.expand-search-bar input::placeholder {
    color: rgb(255, 255, 255); /* Replace with your desired color */
}

svg {
    height: 24px;
    width: 24px;
}
