@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');

@font-face {
    font-family: 'BearFont';
    src: url('/public/bear-font.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BearFont2';
    src: url('/public/bear-font-2.otf') format('truetype');
  }

@media (min-width: 1536px) {
    .ordinals-grid {
        grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
        row-gap: 1rem !important;
        padding-top: 1rem !important;
        column-gap: 1rem !important;
        margin-left: 1rem;
    }

    .filter-parent {
        display: block !important;
    }

    .image-container {
        width: 90%;
    }

    .ordinal img {
        transform: scale(1.13);
    }

    .ordinal img:hover {
        transform: scale(1.14) !important;
    }

    .grid-main {
        padding-top: 9rem !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
    
    .buynow-flex::before {
        margin-right: 10px;
        margin-left: -13px;
    }

    .buynow-flex {
        padding-top: 15px;
    }

    .ordinal-price {
        color: white;
        margin-left: 15px;
    }
    
    .buynow-button {
        margin-right: 20px !important;
        padding: 0.4rem;
        border-radius: 10px;
    }
}


@media (min-width: 1024px) and (max-width: 1536px) {
    .ordinals-grid {
        grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
    }

    .filter-parent {
        display: block !important;
    }

    .grid-main {
        padding-top: 9rem !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}

@media (min-width: 1024px) {

      .grid-header {
        display: flex;
        padding-bottom: 0rem !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        text-transform: uppercase;
    }

    .gallery-container {
        display: flex;
        height: 100%;
        max-width: 1750px;
        margin: 0 auto;
        font-family: 'BearFont2', sans-serif !important;
        letter-spacing: 2px;
        position: relative;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
    }
}

.grid-header {
    display: flex;
    padding-bottom: 0rem;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.grid-main {
    padding-top: 8rem;
    padding-left: 0rem;
    padding-right: 0rem;
}

.ordinals-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-top: 0rem;
    padding: 0;
    row-gap: 0.3rem;
    column-gap: 1rem;
}

.ordinal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 100%;
}

.ordinal-title {
    opacity: 0.5;
    margin-top: 0.75rem;
    text-transform: uppercase;
    font-family: monospace;
    letter-spacing: 0.1em;
    font-size: 0.625rem;
    text-align: center;
    color: white;
}

.ordinal-number {
    font-weight: 400;
    padding-bottom: 0rem;
    margin-top: -0.4rem;
    font-size: 0.7rem;
    letter-spacing: 0.05em;
    text-align: center;
    text-transform: uppercase;
    color: white;
}

.ordinal img:hover {
    transform: scale(1.01);
    transition-duration: .3s;
}

.ordinal img {
    width: 100%;
    border-radius: 0.75rem;
    max-height: 100%;
    object-fit: cover;
    background-color: rgb(161, 158, 153);
    transition-duration: .3s;
    overflow: hidden;
    position: relative;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.gallery-container {
    display: flex;
    height: 100%;
    max-width: 1750px;
    margin: 0 auto;
    /* center the container horizontally */
    position: relative;
    padding-left: 1rem;
    padding-right: 1rem;
}

.trait-type {
    font-weight: bold;
    color: #333;
    margin-right: 5px;
}

.trait-value {
    color: #292929;
    font-size: 0.9rem;
}

a {
    text-decoration: none;
}

.button-container-page {
    padding-left: 2rem;
}

img.filter-logo {
    max-width: 100%;
    height: auto;
    background-color: transparent;
}

.grid-main {
    flex-grow: 3;
    width: 75%;
}

.refresh-button {
    position: relative;
    background-color: transparent;
    margin-left: -10px;
}

.refresh-button::before {
    content: "";
    position: absolute;
    top: 8px;
    left: 0;
    right: 0;
    bottom: 16px;
    background-color: #00000008;
    z-index: -1;
    border-radius: 4px;
}

.refresh-button::before:hover {
    background-color: #0000001A;
  }  

  
.refresh-button:hover {
    background-color: transparent;
}

.refresh-button:hover .refresh-svg {
    opacity: 0.5;
    transition: opacity 0.3s ease;
}

.refresh-svg {
    width: 30px;
    height: 22px;
    padding-top: 3px;
    filter: invert(1);
}

.switch-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #383953;
    padding: 4px;
    margin-top: 18px;
    margin-bottom: 16px;
    border-radius: 15px;
    width: 250px;
    height: 40px;
}

.gridhead-container {
    display: flex;
    align-items: center;
    padding: 4px;
    margin-top: 18px;
    margin-bottom: 16px;
    border-radius: 15px;
    height: 40px;
    pointer-events: none;
    margin-left: 0;
}

.og-switch, .new-switch {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    width: 49%;
    height: 100%;
    background-color: #43445e;
}

.switch-title {
    font-size: 30px; /* Adjust this value to fit the text within the buttons */
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    margin: 0; /* Remove margin to prevent overflow */
    padding: 0; /* Remove padding to prevent overflow */
}

.active-switch {
    background: #aa781c;
  }

.ordinal-count-refresh {
    background-color: #383953a8;
    border-radius: 12px;
    display: flex;
    align-items: center;
}
  

.buynow-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 1;
}

.buynow-flex::before {
    content: '';
    position: absolute;
    top: -10px; /* Adjust the value as needed */
    left: 0;
    right: 0;
    height: calc(100% + 10px); /* Adjust the value to extend the background */
    background-color: #414375;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    z-index: -1;
}

.ordinal-price {
    color: white;
    margin-left: 15px;
}

.buynow-button {
    margin-right: 10px;
    padding: 0.4rem;
    border-radius: 10px;
}

.image-container {
    line-height: 0;
}

.buynow-flex {
    margin: 0;
    /* rest of the styles */
}

.ordinal img {
    margin: 0;
    /* rest of the styles */
    z-index: 2;
}

.image-container {
    position: relative;
  }
  
  .image-text {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    z-index: 2;
    margin-left: -5px;
  }
  
  .red-rarity {
    color: rgb(249, 82, 0);
    text-shadow: currentcolor 0px 0px 16px;
  }

  .gray-rarity {
    color: rgb(146, 146, 146);
  }

  .white-rarity {
    color: rgb(255, 255, 255);
  }

  .gold-rarity {
    color: rgb(246, 174, 45);
    text-shadow: currentcolor 0px 0px 16px;
  }
  
  .image-text::before {
    content: '';
    position: absolute;
    top: -6px; /* Adjust the top value to increase the size of the background box */
    left: -2px; /* Adjust the left value to increase the size of the background box */
    right: 0px; /* Adjust the right value to increase the size of the background box */
    bottom: -3px; /* Adjust the bottom value to increase the size of the background box */
    background-color: #292929;
    z-index: -1;
    border-radius: 5px;
  }


  .image-text-2 {
    position: absolute;
    top: 0;
    left: 0;
    padding: 25px;
    z-index: 999;
    margin-left: 0px;
    font-size: 25px;
  }

  .image-text-2::before {
    content: '';
    position: absolute;
    top: 7px; /* Adjust the top value to increase the size of the background box */
    left: 7px; /* Adjust the left value to increase the size of the background box */
    right: 10px; /* Adjust the right value to increase the size of the background box */
    bottom: 10px; /* Adjust the bottom value to increase the size of the background box */
    background-color: #292929;
    z-index: -1;
    border-radius: 10px;
  }

  @media (max-width: 1536px) {

    .image-text {
        position: absolute;
        top: 11px;
        left: 12px;
        padding: 10px;
        z-index: 2;
        margin-left: -5px;
      }


    .image-text::before {
        content: '';
        position: absolute;
        top: -6px; /* Adjust the top value to increase the size of the background box */
        left: -2px; /* Adjust the left value to increase the size of the background box */
        right: 0px; /* Adjust the right value to increase the size of the background box */
        bottom: -3px; /* Adjust the bottom value to increase the size of the background box */
        background-color: #292929;
        z-index: -1;
        border-radius: 5px;
      }

  }

  .top1-button, .top10-button, .top25-button {
    background-color: #6b6b85;
    border: 1px solid rgba(255, 255, 255, 0.402);
    max-width: 100px;
    white-space: nowrap;
}

@media (max-width: 1350px) {
    .top1-button, .top10-button, .top25-button {
        background-color: #6b6b85;
        border: 1px solid rgba(255, 255, 255, 0.402);
        max-width: 100px;
        white-space: nowrap;
        font-size: 10px;
    }

}

@media (max-width: 1024px) {
    .top1-button, .top10-button, .top25-button {
        background-color: #6b6b85;
        border: 1px solid rgba(255, 255, 255, 0.402);
        max-width: 100px;
        white-space: nowrap;
        font-size: 20px;
        margin-right: 20px;
        margin-left: 20px;
        padding: 10px;
    }

}

.magic-eden-logoo {
    height: 24px;
    width: 24px;
    border-radius: 9px !important;
    margin-right: 5px;
}

.me-logo {
    position: relative;
    margin-right: 17px;
    margin-top: 5px;
}