.OGBEARS-section {
    padding: 2rem;
    padding-right: 6rem;
    color: #fff;
    margin-left: auto;
    font-family: 'BearFont2', sans-serif;
    padding-left: 25rem;
    padding-right: 25rem;
    
  }

  .OGBEARS-description {
    font-size: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 12rem;
    padding-right: 12rem;
    line-height: 1.4;
  }

  .OGBEARS-title {
    font-size: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    
  }

  .ogbear-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ogbear-item button {
    font-size: 40px;
    margin-top: 1rem;
    padding-top: 14px;
  }

  @media (max-width: 1024px) {
    .OGBEARS-section {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  
    .OGBEARS-description {
      font-size: 18px;
      padding-left: 0rem;
      padding-right: 0rem;
    }
  
    .OGBEARS-title {
      font-size: 40px;
    }
  
    .ogbear-item button {
      font-size: 20px;
      padding-top: 10px;
    }
  }
  
  @media (min-width: 1024px) and (max-width: 1400px) {
    .OGBEARS-section {
        padding-left: 10rem;
        padding-right: 10rem;
      }
    
      .OGBEARS-description {
        font-size: 20px;
        padding-left: 6rem;
        padding-right: 6rem;
    
      }
    
      .OGBEARS-title {
        font-size: 60px;
      }
    
      .ogbear-item button {
        font-size: 30px;
        padding-top: 10px;
      }
  }

  @media (min-width: 1400px) and (max-width: 1650px) {
    .OGBEARS-section {
        padding-left: 15rem;
        padding-right: 15rem;
      }
    
      .OGBEARS-description {
        font-size: 22px;
        padding-left: 8rem;
        padding-right: 8rem;
      }
    
      .OGBEARS-title {
        font-size: 70px;
      }
    
      .ogbear-item button {
        font-size: 35px;
        padding-top: 12px;
      }
  }
  