@font-face {
    font-family: 'BearFont';
    src: url('/public/bear-font.ttf') format('truetype');
  }

  @font-face {
    font-family: 'BearFont2';
    src: url('/public/bear-font-2.otf') format('truetype');
  }

.App-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 20px;
    z-index: 500;
    max-width: 1800px;
    margin: 0 auto;
    margin-left: auto;
    margin-right: auto;
}

.img-logo {
    margin-top: 1rem;
    position: relative;
    z-index: 102;
}

.hamburger {
    display: none;
    cursor: pointer;
    background: transparent;
    border: none;
    outline: none;
    padding: 0;
    margin: 0;
    font-size: 2rem;
    color: #222;
    z-index: 101;
}

.hamburger svg {
    filter: invert(1);
}

.button-gap {
    width: 0.5rem;
}

.button-container-page {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.button-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
}

button {
    cursor: pointer;
    background-color: #00000008;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 5px;
    font-family: 'BearFont2', sans-serif !important;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-size: 16px;
}


@media (min-width: 1024px) {
    button:hover {
        background-color: #0000001A;
    }
}

.button-svg-item svg {
    height: 18px;
    width: 18px;
}

.discord-svg-item svg {
    height: 24px;
    width: 24px;
    margin-top: -2px;
}

.button-svg-item {
    height: 34px;
    width: 85px;
}

.discord-svg-item {
    height: 34px;
    width: 85px;
}

.connect-wallet-item {
    text-transform: uppercase;
}

.logo-button {
    background-color: #383953;
    color: white;
    margin-top: 30px;
}

.button-svg-item svg,
.discord-svg-item svg,
.refresh-svg svg path {
    filter: invert(1);
}

button {
    color: rgb(238, 238, 238);
    background-color: #d6a851;
}

button:hover {
    background-color: #aa781c;
}

.bear-logo-image {
    height: 150px;
    position: fixed;
    top: 5px;
    left: 40px;

}

@media only screen and (max-width: 1024px) {

    .bear-logo-image {
        height: 70px;
        position: fixed;
        top: 7px;
        left: 20px;
        z-index: 999;
    }

}

.button-backdrop {
    background-color: #8a6857;
    border-radius: 6px;
    padding: 0.05rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.home-button-backdrop {
    background-color: #8a6857;
    border-radius: 6px;
    padding: 0.05rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
    button:hover {
        background-color: transparent;
    }
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-button {
    max-width: 125px;
    border: 1.5px solid #8a6857;
}

.dropdown-button svg {
height: 12px;
width: 12px;
}

.dropdown-content {
    display: none;
    position: absolute;
    min-width: 125px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    background-color: #d6a851;
    border: 1.5px solid #8a6857;
    border-radius: 10px;
    margin-top: 7px;
    min-height: 140px;
    z-index: 1;
}

.dropdown-content button {
    text-decoration: none;
    display: block;
    max-width: 125px;
    width: 125px;
    margin-top: 5px;
    text-align: left;
    font-size: 12px;
    white-space: nowrap;
}

.dropdown-content button:hover {
background-color: transparent;
transform: scale(1.02); /* Adjust scale as needed */
}

.dropdown-open .dropdown-content {
    display: block;
}